import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"
import NavBar from "../components/navbar"
import SEO from "../components/SEO/index"
import Slick from "../components/Slick"
import { Section, SectionTitle } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import { Box } from "../components/CompoundComponents/Box"
import Footer from "../components/Footer/index"

const TreatmentPage = ({ data, intl }) => {
  return (
    <>
      <SEO
        title="Ниҳол саломатлик сиҳатгоҳи, Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган"
        description="Один из лучших санаториев Узбекистана - Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган, Ниҳол саломатлик сиҳатгоҳи"
      />
      <NavBar />
      <Section>
        <Columns>
          <Column is8>
            <Slick slideToShow={1} slideToScroll={1} speed={3000}>
              {data.treatment.edges.map(item => (
                <Img
                  fluid={item.node.featuredImage.childImageSharp.fluid}
                  alt={item.node.id}
                />
              ))}
            </Slick>
            <Box>
              <div className="content">
                <SectionTitle>
                  {" "}
                  {intl.formatMessage({
                    id: `treatment.title-1`,
                  })}
                </SectionTitle>
                <h3>
                  {intl.formatMessage({
                    id: `treatment.desc-1`,
                  })}
                </h3>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-3`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-4`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-5`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-6`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-1.item-7`,
                    })}
                  </li>
                </ul>
                <h3>
                  {intl.formatMessage({
                    id: `treatment.desc-2`,
                  })}
                </h3>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-2.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-2.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-2.item-3`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-2.item-4`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-2.item-5`,
                    })}
                  </li>
                </ul>
                <h3>
                  {intl.formatMessage({
                    id: `treatment.desc-3`,
                  })}
                </h3>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-3.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-3.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-3.item-3`,
                    })}
                  </li>
                </ul>
                <h3>
                  {intl.formatMessage({
                    id: `treatment.desc-4`,
                  })}
                </h3>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-4.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-4.item-2`,
                    })}
                  </li>
                </ul>
                <h3>
                  {intl.formatMessage({
                    id: `treatment.desc-6`,
                  })}
                </h3>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-6.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-6.item-2`,
                    })}
                  </li>
                </ul>
                <h3>
                  {intl.formatMessage({
                    id: `treatment.desc-7`,
                  })}
                </h3>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-7.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-7.item-2`,
                    })}
                  </li>
                </ul>
                <h3>
                  {intl.formatMessage({
                    id: `treatment.desc-8`,
                  })}
                </h3>
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-8.item-1`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-8.item-2`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-8.item-3`,
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: `treatment.list-8.item-4`,
                    })}
                  </li>
                </ul>
              </div>
            </Box>
          </Column>
          <Column is4>
            <Box>
              <div className="content">
                <h3>
                  {intl.formatMessage({
                    id: `treatment.title`,
                  })}
                </h3>
                <p>
                  {intl.formatMessage({
                    id: `index.treatment.desc`,
                  })}
                </p>
              </div>
            </Box>
            <Box white>
              <article className="message is-primary">
                <div className="message-body">
                  <p>
                    {intl.formatMessage({
                      id: `treatment.attention.title`,
                    })}
                  </p>
                  <br />
                  <p>
                    {intl.formatMessage({
                      id: `treatment.attention.desc-1`,
                    })}
                  </p>
                  <br />
                  <p>
                    {intl.formatMessage({
                      id: `treatment.attention.desc-2`,
                    })}
                  </p>
                </div>
              </article>
            </Box>
          </Column>
        </Columns>
      </Section>
      <Footer data={data.section.edges[0]} />
    </>
  )
}

export const IndexPageQuery = graphql`
  query {
    treatment: allTreatmentYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    section: allProductsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(TreatmentPage)
